var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-form",
        { staticClass: "mt-2", attrs: { inline: "" } },
        [
          _c("b-form-select", {
            staticClass: "mr-2 small-select",
            attrs: { options: _vm.authentication_types },
            on: { input: _vm.getLinks },
            model: {
              value: _vm.authentication_type,
              callback: function ($$v) {
                _vm.authentication_type = $$v
              },
              expression: "authentication_type",
            },
          }),
          _c("b-form-radio-group", {
            staticClass: "mt-2",
            attrs: { id: "radio-group-1", options: _vm.options },
            on: { input: _vm.changeList },
            model: {
              value: _vm.viewed,
              callback: function ($$v) {
                _vm.viewed = $$v
              },
              expression: "viewed",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("b-table", {
            attrs: {
              small: "",
              bordered: "",
              items: _vm.items,
              hover: "",
              fields: _vm.fields,
              busy: _vm.loading,
              "tbody-tr-class": _vm.rowClass,
            },
            on: { "row-clicked": _vm.clickedRow },
            scopedSlots: _vm._u([
              {
                key: "cell(created_date)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.FormatDateTime(data.item.created_date)) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(size)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.bytesToSize(data.item.size)) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(authentication_method)",
                fn: function (data) {
                  return [
                    _c("b-img", {
                      staticClass: "authIcon",
                      attrs: {
                        alt: _vm.$t(
                          "AUTH_METHODS." + data.item.authentication_method
                        ),
                        src: _vm.authenticationImage(
                          data.item.authentication_method
                        ),
                      },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "AUTH_METHODS." + data.item.authentication_method
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(viewed)",
                fn: function (data) {
                  return [
                    _c("MessageStatus", {
                      attrs: { status: data.item.status },
                    }),
                  ]
                },
              },
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _c("div", { staticClass: "text-break" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(data.item.subject) +
                          "\n        "
                      ),
                    ]),
                    _c("div", { staticClass: "time-information f12" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.FormatDateTime(data.item.created_date)) +
                          "\n        "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "f12" },
                      [
                        _c("b-img", {
                          staticClass: "authIcon",
                          attrs: {
                            alt: _vm.$t(
                              "AUTH_METHODS." + data.item.authentication_method
                            ),
                            src: _vm.authenticationImage(
                              data.item.authentication_method
                            ),
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(data.item.email) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                    _c("small", [
                      !data.item.viewed
                        ? _c("span", [
                            _c("i", { staticClass: "fal fa-lock" }),
                            _vm._v(" " + _vm._s(_vm.$t("NOT_VIEWED"))),
                          ])
                        : _vm._e(),
                      data.item.viewed
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("VIEWED")) +
                                "\n            " +
                                _vm._s(
                                  _vm.FormatDateTime(data.item.viewed_date)
                                )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [_c("b-spinner", { staticClass: "align-middle" })],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("b-pagination", {
            staticClass: "mt-2",
            attrs: {
              "total-rows": _vm.totalItems,
              "per-page": _vm.limit,
              align: "center",
              pills: "",
            },
            on: { input: _vm.getLinks },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _c(
            "b-sidebar",
            {
              attrs: {
                right: "",
                "no-slide": "",
                shadow: "",
                lazy: "",
                backdrop: "",
              },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _vm.showItem
                ? _c("SecureLinkInformation", {
                    key: _vm.showItem.id,
                    attrs: {
                      message_link: 1,
                      index: _vm.showIndex,
                      item: _vm.showItem,
                      functionbox_uuid: _vm.functionbox_uuid,
                      user_uuid: _vm.user_uuid,
                    },
                    on: { gotoMessage: _vm.gotoMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }