var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c("span", [
        _vm.information.name == undefined
          ? _c("span", [
              _vm.information == _vm.user.information.email
                ? _c("span", [
                    !_vm.noIcon
                      ? _c("i", { staticClass: "fal fa-user" })
                      : _vm._e(),
                    _vm._v(
                      " " + _vm._s(_vm.user.information.name) + "\r\n    "
                    ),
                  ])
                : _vm._e(),
              _vm.information != _vm.user.information.email
                ? _c("span", [
                    _vm._v("\r\n      " + _vm._s(_vm.information) + "\r\n    "),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.information.item_type == "message-fax"
          ? _c("span", [
              !_vm.noIcon ? _c("i", { staticClass: "fal fa-phone" }) : _vm._e(),
              _vm._v(" " + _vm._s(_vm.information.email) + "\r\n  "),
            ])
          : _vm._e(),
        _vm.information.item_type == "addressbook"
          ? _c("span", [
              !_vm.noIcon
                ? _c("i", { staticClass: "fal fa-address-book" })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.information.name) + " "),
              !_vm.short
                ? _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(_vm.information.email) +
                        _vm._s(_vm.information.phone) +
                        ")"
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.information.item_type == "graph"
          ? _c("span", [
              !_vm.noIcon
                ? _c("i", { staticClass: "fab fa-windows" })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.information.name) + " "),
              !_vm.short
                ? _c("span", [
                    _vm._v("("),
                    _vm.information.email
                      ? _c("span", [_vm._v(_vm._s(_vm.information.email))])
                      : _vm._e(),
                    _vm.information.phone
                      ? _c("span", [_vm._v(_vm._s(_vm.information.phone))])
                      : _vm._e(),
                    _vm._v(")"),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.information.item_type == "message-link"
          ? _c("span", [
              !_vm.noIcon && _vm.validateEmail(_vm.information.email)
                ? _c("i", { staticClass: "fal fa-envelope" })
                : _vm._e(),
              !_vm.noIcon && _vm.validatePhone(_vm.information.email)
                ? _c("i", { staticClass: "fal fa-phone" })
                : _vm._e(),
              _vm._v("\r\n    " + _vm._s(_vm.information.email) + "\r\n  "),
            ])
          : _vm._e(),
        _vm.information.item_type == "message-user"
          ? _c("span", [
              !_vm.noIcon
                ? _c("img", {
                    staticClass: "icon mr",
                    attrs: {
                      src: "/img/message_type/message.png",
                      alt: _vm.$t("SEFOS_USER"),
                    },
                  })
                : _vm._e(),
              _vm._v("  " + _vm._s(_vm.information.email) + "\r\n  "),
            ])
          : _vm._e(),
        _vm.information.item_type == "user"
          ? _c("span", [
              !_vm.noIcon
                ? _c("img", {
                    staticClass: "icon mr",
                    attrs: {
                      src: "/img/message_type/message.png",
                      alt: _vm.$t("SEFOS_USER"),
                    },
                  })
                : _vm._e(),
              _vm._v("  " + _vm._s(_vm.information.name)),
              !_vm.short
                ? _c("span", [
                    _vm.information.title
                      ? _c("span", [
                          _vm._v(", " + _vm._s(_vm.information.title)),
                        ])
                      : _vm._e(),
                    _vm.information.department
                      ? _c("span", [
                          _vm._v(", " + _vm._s(_vm.information.department)),
                        ])
                      : _vm._e(),
                    _vm._v(
                      ", " +
                        _vm._s(_vm.information.organisation) +
                        " (" +
                        _vm._s(_vm.information.email) +
                        ")"
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.information.item_type == "functionbox"
          ? _c("span", [
              !(_vm.noIcon || _vm.isMeeting)
                ? _c("img", {
                    staticClass: "icon mr",
                    attrs: {
                      src: "/img/message_type/message.png",
                      alt: _vm.$t("FUNCTIONBOX.TITLE"),
                    },
                  })
                : _vm._e(),
              _vm._v("  " + _vm._s(_vm.information.name)),
              !_vm.short
                ? _c("span", [
                    _vm._v(
                      ",  " +
                        _vm._s(_vm.information.organisation) +
                        "(" +
                        _vm._s(_vm.information.email) +
                        ")"
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }