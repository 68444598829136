<template>
  <section class="mt-2">
    <b-form inline class="mt-2">
      <b-form-select
        v-model="authentication_type"
        :options="authentication_types"
        class="mr-2 small-select"
        @input="getLinks"
      >
      </b-form-select>
      <b-form-radio-group
        class="mt-2"
        @input="changeList"
        id="radio-group-1"
        v-model="viewed"
        :options="options"
      ></b-form-radio-group>
    </b-form>

    <div class="mt-2">
      <b-table
        small
        bordered
        :items="items"
        hover
        :fields="fields"
        @row-clicked="clickedRow"
        :busy="loading"
        :tbody-tr-class="rowClass"
      >
        <template v-slot:cell(created_date)="data">
          {{
            FormatDateTime(data.item.created_date)
          }}
        </template>

        <template v-slot:cell(size)="data">
          {{ bytesToSize(data.item.size) }}
        </template>

        <template v-slot:cell(authentication_method)="data">
          <b-img
            :alt="$t('AUTH_METHODS.' + data.item.authentication_method)"
            class="authIcon"
            :src="authenticationImage(data.item.authentication_method)"
          ></b-img>
          {{ $t("AUTH_METHODS." + data.item.authentication_method) }}
        </template>

        <template v-slot:cell(viewed)="data">
          <MessageStatus :status="data.item.status"></MessageStatus>
        </template>

        <template v-slot:cell(id)="data">
          <div class="text-break">
            {{ data.item.subject }}
          </div>
          <div class="time-information f12">
            {{
              FormatDateTime(data.item.created_date)
            }}
          </div>
          <div class="f12">
            <b-img
              :alt="$t('AUTH_METHODS.' + data.item.authentication_method)"
              class="authIcon"
              :src="authenticationImage(data.item.authentication_method)"
            ></b-img>
            {{ data.item.email }}
          </div>
          <small>
            <span v-if="!data.item.viewed"
              ><i class="fal fa-lock"></i> {{ $t("NOT_VIEWED") }}</span
            >
            <span v-if="data.item.viewed"
              >{{ $t("VIEWED") }}
              {{
                FormatDateTime(data.item.viewed_date)
              }}</span
            >
          </small>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="limit"
        align="center"
        pills
        @input="getLinks"
      >
      </b-pagination>

      <b-sidebar v-model="show" right no-slide shadow lazy backdrop>
        <SecureLinkInformation
          v-if="showItem"
          :key="showItem.id"
          :message_link="1"
          :index="showIndex"
          :item="showItem"
          :functionbox_uuid="functionbox_uuid"
          :user_uuid="user_uuid"
          @gotoMessage="gotoMessage"
        ></SecureLinkInformation
      ></b-sidebar>
    </div>
  </section>
</template>
<script>
import MessageStatus from "@/components/View/MessageStatus";
import SecureLinkInformation from "@/components/View/Recipients/SecureLinkInformation";
export default {
  props: ["searchText", "limit", "functionbox_uuid", "user_uuid"],
  components: { MessageStatus, SecureLinkInformation },
  data() {
    return {
      loading: false,
      generatedColors: false,
      showResponse: false,
      currentPage: 1,
      show: false,
      showItem: null,
      showIndex: 0,
      items: [],
      totalItems: 0,
      viewed: 100,
      authentication_types: [{ value: "", text: this.$t("ALL") }],
      authentication_type: "",
      options: [
        { text: this.$t("ALL"), value: 100 },
        { text: this.$t("VIEWED"), value: 1 },
        { text: this.$t("NOT_VIEWED"), value: 0 },
        { text: this.$t("RECALLED"), value: 2 },
      ],
      fields: [
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          key: "viewed",
          label: "",
          class: "text-break w-icon clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "created_date",
          label: this.$t("CREATED"),
          class: "text-break w-date clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("EMAIL"),
          class: "text-break w-300 clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "subject",
          label: this.$t("SUBJECT"),
          class: "text-break w-300 clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "authentication_method",
          label: this.$t("AUTH_METHODS.VERIFY"),
          class: "text-break w-300  clickable hidden-mobile",
          sortable: false,
        }
      ]
    };
  },
  methods: {
    gotoMessage(message_uuid) {
      this.showItem = false;
      this.show = false;
      this.$emit("viewingMessage", message_uuid);
    },
    rowClass(item, type) {
      if (item && type === "row") {
        if (item.response_message_uuid != "") {
          return "";
        }
        if (item.viewed === 0) {
          return "not-viewed";
        }
      }
      return "";
    },
    changeList() {
      this.currentPage = 1;
      this.getLinks();
    },
    clickedRow(data, index) {
      this.showItem = data;
      this.showIndex = index;
      this.show = true;
    },
    getColor(index) {
      let colorIndex = index;
      if (index > 43) {
        colorIndex = index - 43;
      }
      if (index > 86) {
        colorIndex = index - 86;
      }
      return this.colors[colorIndex];
    },
    bytesToSize(bytes) {
      var sizes = ["B", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    getStatus(item) {
      if (item.response_message_uuid != "") {
        return 3;
      }
      if (item.recalled == 1) {
        return 2;
      }
      if (item.viewed == 1) {
        return 1;
      }
      if (item.sent != undefined) {
        if (item.sent == 0) {
          return 10;
        }
      }
      return 0;
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/external/methods")
        .then(function(result) {
          for (let method of result.data) {
            self.authentication_types.push({
              value: method.name,
              text: self.$t("AUTH_METHODS." + method.name),
            });
          }
        })
        .catch(function() {
          self.loading = false;
        });
    },
    authenticationImage(authentication_method) {
      return "/img/auth/" + authentication_method + ".png";
    },
    getLinks: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/inbox/links", {
          functionbox_uuid: this.functionbox_uuid,
          user_uuid: this.user_uuid,
          page: this.currentPage,
          limit: parseInt(this.limit),
          viewed: this.viewed,
          authentication_method: this.authentication_type,
          search_text: this.searchText,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    searchText: function() {
      if (this.searchText.length == 0) {
        this.getLinks();
      }
      if (this.searchText.length >= 2) {
        this.getLinks();
      }
    },
    limit: function() {
      this.getLinks();
    },
  },
  mounted: function() {
    this.getMethods();
    this.getLinks();
  },
};
</script>
<style></style>
